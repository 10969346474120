import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import("@/views/Home/Home.vue"),
    },
    {
      path: '/login_check',
      name: 'LoginCheck',
      component: () => import("@/views/Home/LoginCheck.vue"),
    },
    {
      path: '/unite',
      name: 'Unite',
      component: () => import("@/views/Unite/Unite.vue"),
    },
    {
      path: '/configure_reddit',
      name: 'ConfigureReddit',
      component: () => import("@/views/Unite/ConfigureReddit.vue"),
    },
    {
      path: '/configure_bluesky',
      name: 'ConfigureBlueSky',
      component: () => import("@/views/Unite/ConfigureBlueSky.vue"),
    },
    {
      path: '/configure_youtube',
      name: 'ConfigureYouTube',
      component: () => import("@/views/Unite/ConfigureYouTube.vue"),
    },
    {
      path: '/goread',
      name: 'GoRead',
      component: () => import("@/views/GoRead/GoRead.vue"),
    },
    {
      path: '/books',
      name: 'Books',
      component: () => import("@/views/GoRead/Books.vue"),
    },
    {
      path: '/configure_books',
      name: 'ConfigureBooks',
      component: () => import("@/views/GoRead/ConfigureBooks.vue"),
    },
    {
      path: '/read_book/:book',
      name: 'ReadBook',
      component: () => import("@/views/GoRead/ReadBook.vue"),
    },
    {
      path: '/mangas',
      name: 'Mangas',
      component: () => import("@/views/GoRead/Mangas.vue"),
    },
    {
      path: '/configure_mangas',
      name: 'ConfigureMangas',
      component: () => import("@/views/GoRead/ConfigureMangas.vue"),
    },
    {
      path: '/read_manga/:manga',
      name: 'ReadManga',
      component: () => import("@/views/GoRead/ReadManga.vue"),
    },
    {
      path: '/stockpile',
      name: 'StockPile',
      component: () => import("@/views/StockPile/StockPile.vue"),
    },
    {
      path: '/catalog',
      name: 'Catalog',
      component: () => import("@/views/StockPile/Catalog.vue"),
    },
    {
      path: '/categories',
      name: 'Categories',
      component: () => import("@/views/StockPile/Categories.vue"),
    },
    {
      path: '/itemnames',
      name: 'ItemNames',
      component: () => import("@/views/StockPile/ItemNames.vue"),
    },
    {
      path: '/stock',
      name: 'Stock',
      component: () => import("@/views/StockPile/Stock.vue"),
    },
    {
      path: '/music/bs',
      name: 'BS',
      component: () => import("@/views/Music/BS.vue"),
    },
    {
      path: '/music/bs-saved',
      name: 'BSSaved',
      component: () => import("@/views/Music/BSSaved.vue"),
    },
    {
      path: '/music/c3-saved',
      name: 'C3Saved',
      component: () => import("@/views/Music/C3Saved.vue"),
    },
    {
      path: '/music/c3',
      name: 'Music',
      component: () => import("@/views/Music/C3.vue"),
    },
    {
      path: '/music/concerts',
      name: 'Concerts',
      component: () => import("@/views/Music/Concerts.vue"),
    },
    {
      path: '/speed',
      name: 'Speed',
      component: () => import("@/views/Speed/Speed.vue"),
    },
    {
      path: '/health',
      name: 'Health',
      component: () => import("@/views/Health/Health.vue"),
    },
    {
      path: '/workout',
      name: 'Workout',
      component: () => import("@/views/Workout/Workout.vue"),
    },
    {
      path: '/exercises',
      name: 'Exercises',
      component: () => import("@/views/Workout/Exercises.vue"),
    },
    {
      path: '/plans',
      name: 'Plans',
      component: () => import("@/views/Workout/Plans.vue"),
    },
    {
      path: '/edit_plan/:planID',
      name: 'editPlan',
      component: () => import("@/views/Workout/EditPlan.vue"),
    },
    {
      path: '/edit_plan_group/:planID/:groupID',
      name: 'editPlanGroup',
      component: () => import("@/views/Workout/EditPlanGroup.vue"),
    },
    {
      path: '/start_plan/:planID',
      name: 'startPlan',
      component: () => import("@/views/Workout/StartPlan.vue"),
    },
    {
      path: '/jack',
      name: 'Jack',
      component: () => import("@/views/Jack/Jack.vue"),
    },
    {
      path: '/start_session',
      name: 'StartSession',
      component: () => import("@/views/Jack/StartSession.vue"),
    },
    {
      path: '/session/:sessionID',
      name: 'Session',
      component: () => import("@/views/Jack/Session.vue"),
    },
    {
      path: '/stories',
      name: 'Stories',
      component: () => import("@/views/Stories/Stories.vue"),
    }
  ],
})
